<!-- 快递单号计费 -->
<template>
  <div class="logistics-list">
    <div class="list-container">
      <div class="search-container">
        <div class="sc-item">
          <label class="sci-title">运单号</label>
          <el-input
            placeholder="请输入运单号"
            v-model="searchData.waybillno"
            clearable
          >
          </el-input>
        </div>
        <div class="sc-item">
          <label class="sci-title">客户</label>
          <el-input
            placeholder="请输入客户"
            v-model="searchData.user"
            clearable
          >
          </el-input>
        </div>
        <div class="sc-item">
          <label class="sci-title">子代理</label>
          <el-select
            style="width: 300px"
            v-model="searchData.agency"
            filterable
            multiple
            placeholder="请选择"
            clearable
            @change="handleAgencyChild"
          >
            <el-option
              v-for="item in agencyChildren"
              :key="item.agency_code"
              :label="item.agency_name"
              :value="item.agency_code"
            >
            </el-option>
          </el-select>
        </div>
        <div class="sc-item">
          <label class="sci-title">快递公司</label>
          <el-select
            v-model="searchData.delivery"
            filterable
            multiple
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in deliveryTypes"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </div>
        <div class="sc-item">
          <label class="sci-title">快递单号</label>
          <el-input
            placeholder="请输入快递单号"
            v-model="searchData.deliveryno"
            clearable
          >
          </el-input>
        </div>
        <div class="sc-item">
          <label class="sci-title">获取日期</label>
          <el-date-picker
            v-model="searchData.createitme"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="sc-item">
          <el-button @click="handleSearch" icon="el-icon-search" type="primary"
            >查询</el-button
          >
          <el-button @click="handleReset" icon="el-icon-refresh-left"
            >重置</el-button
          >
          <el-button type="warning" @click="handelExportExcel"
            >导出Excel</el-button
          >
        </div>
      </div>
      <div v-if="deliveryTotal" class="delivery-total">
        当前查询运费金额:<span>{{ deliveryTotal }}</span
        >元
      </div>
      <div class="table-container">
        <el-table
          :data="tableData"
          border
          header-cell-class-name="table-header"
        >
          <el-table-column
            type="index"
            :index="calcIndex"
            label="序号"
            width="50"
            fixed
          >
          </el-table-column>
          <el-table-column fixed prop="waybillNumber" label="运单号">
          </el-table-column>
          <el-table-column prop="deliveryName" label="快递公司">
          </el-table-column>
          <el-table-column prop="deliveryNo" label="快递单号">
          </el-table-column>
          <el-table-column prop="deliveryFees" label="单号费">
          </el-table-column>
          <el-table-column prop="createtime" label="获取时间">
          </el-table-column>
          <el-table-column prop="agencyName" label="代理商"> </el-table-column>
          <el-table-column prop="waybillClient" label="客户"> </el-table-column>
          <el-table-column label="保价"> 
            <template slot-scope="scope">
              <span>{{ scope.row.insureFee | toInt }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="pageLayout"
        :total="tableTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { PAGESIZELIST, PAGE_LAYOUT, PAGESIZE } from 'config/index'
import Expressagebill from 'api/expressage'
import Commonbill from 'api/common'
import { GetToken } from 'utils/auth'
import tableIndex from '@/mixins/table-index'
export default {
  components: {},
  name: '',
  mixins: [tableIndex],
  data() {
    return {
      currentPage: 1,
      pageSize: PAGESIZE,
      pageLayout: PAGE_LAYOUT,
      pageSizes: PAGESIZELIST,
      searchData: {
        waybillno: '', // 运单号
        user: '', // 客户
        agency: [], // 子代理
        delivery: [], // 快递公司
        deliveryno: '', // 快递单号
        createitme: '' // 获取日期
      },
      agencyChildren: [], // 子代理数据
      deliveryTypes: [], // 枚举快递公司
      tableData: [],
      deliveryTotal: 0,
      tableTotal: 0,
      agencyCode: this.$route.query.agency // 代理商自身
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getAgencyChildren()
    this.getDeliveryTypes()
  },
  mounted() {},
  destroyed() {},
  methods: {
    // 导出
    handelExportExcel() {
      Expressagebill.downExpressageExcel({
        ...this.getBaseParams()
      }).then((data) => {
        let fileName = '快递单号费'
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(
            new Blob([data], { type: 'application/vnd.ms-excel' }),
            fileName + '.xlsx'
          )
        } else {
          let url = window.URL.createObjectURL(
            new Blob([data], { type: 'application/vnd.ms-excel' })
          )
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName + '.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link) //下载完成移除元素
          window.URL.revokeObjectURL(url) //释放掉blob对象
        }
      })
    },
    // 查询
    handleSearch() {
      this.currentPage = 1
      this.tableData = []
      this.getList()
    },
    // 重置
    handleReset() {
      this.currentPage = 1
      this.searchData = {
        waybillno: '', // 运单号
        user: '', // 客户
        agency: [], // 子代理
        delivery: [], // 快递公司
        deliveryno: '', // 快递单号
        createitme: '' // 获取日期
      }
      this.tableData = []
      this.getList()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.currentPage = 1
      this.pageSize = val
      this.tableData = []
      this.getList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.tableData = []
      this.getList()
    },
    getBaseParams() {
      let _start = '',
        _end = ''
      if (this.searchData.createitme) {
        _start = this.searchData.createitme[0]
        _end = this.searchData.createitme[1]
      }
      return {
        token: GetToken(),
        waybillno: this.searchData.waybillno,
        user: this.searchData.user,
        agency: this.searchData.agency.length
          ? "'" + this.searchData.agency.join("','") + "'"
          : '',
        delivery: this.searchData.delivery.length
          ? "'" + this.searchData.delivery.join("','") + "'"
          : '',
        deliveryno: this.searchData.deliveryno,
        begin: _start,
        end: _end
      }
    },
    getList() {
      Expressagebill.getExpressageList({
        ...this.getBaseParams(),
        curr: this.currentPage,
        pagesize: this.pageSize
      }).then((res) => {
        this.tableData = res.record
        console.log('==', JSON.parse(res.message))
        this.tableTotal = JSON.parse(res.message).count
        this.deliveryTotal = JSON.parse(res.message).total
        if (res.success) {
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getAgencyChildren() {
      Commonbill.getAgencyChildren({
        token: GetToken()
      }).then((res) => {
        if (res.success) {
          this.agencyChildren = res.record
          if (this.agencyCode) {
            this.agencyChildren.unshift({
              agency_code: this.agencyCode,
              agency_name: '自身'
            })
          }
          this.agencyChildren.unshift({
            agency_code: 'all',
            agency_name: '全部'
          })
          this.agencyChildren.map((item) => {
            this.searchData.agency.push(item.agency_code)
          })
          this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleAgencyChild(val) {
      if (val.includes('all')) {
        this.searchData.agency = []
        this.agencyChildren.map((item) => {
          this.searchData.agency.push(item.agency_code)
        })
      }
    },
    getDeliveryTypes() {
      Commonbill.getDeliveryType({
        token: GetToken()
      }).then((res) => {
        this.deliveryTypes = res.record
        if (res.success) {
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.delivery-total {
  background: #fff;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  span {
    color: red;
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin: 0 5px;
  }
}
</style>
