import { netpost, netdonwloadexcel } from '../plugins/request'

export default {
  // 获取代理商快递单号计费
  getExpressageList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetExpressageList`, params)
  },
  // 获取快递费查询数据
  downExpressageExcel(params, loading = true) {
    Object.assign(params, { loading })
    return netdonwloadexcel(`/api/Logistics/DownExpressageExcel`, params)
  },
  // 大后台获取代理商快递单号计费
  getAdminExpressageList(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetAdminExpressageList`, params)
  },
  // 大后台获取快递费查询数据
  downAdminExpressageExcel(params, loading = true) {
    Object.assign(params, { loading })
    return netdonwloadexcel(`/api/Logistics/DownAdminExpressageExcel`, params)
  }
}
